<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Furnizor" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <v-row dense class="text-no-wrap" align="center">
                <span class="d-none">{{ (record.ctg = "furnizor") }}</span>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="record.fields['200'][0].a[0].val"
                    :rules="validateValue('200', 'a')"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'200a ' + $t('Furnizor')"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="record.fields['210'][0].g[0].val"
                    :rules="validateValue('210', 'g')"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'210g ' + $t('Localitate')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="record.fields['210'][0].a[0].val"
                    :rules="validateValue('210', 'a')"
                    rows="2"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'210a ' + $t('Adresa')"
                  />
                </v-col>
              </v-row>
              <base-material-tabs
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                color="primary"
                center-active
                centered
                fixed-tabs
              >
                <v-tab>{{ $t("Contact") }}</v-tab>
                <v-tab>{{ $t("Banca") }}</v-tab>
                <v-tab>{{ $t("Persoană contact") }}</v-tab>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="record.fields['210'][0].h[0].val"
                        :rules="validateValue('210', 'h')"
                        style="width:250px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'210h ' + $t('Județ')"
                        :items="dictionary.JudeteRomania"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="record.fields['210'][0].l[0].val"
                        :rules="validateValue('210', 'l')"
                        style="width:250px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'210l ' + $t('Țara')"
                        :items="dictionary.countriesAlpha3"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row v-for="(sub, ind) in record.fields['305'][0].t" :key="'305t' + ind" dense>
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-text-field
                            v-model="sub.val"
                            :rules="validateValue('305', 't')"
                            style="width:250px"
                            :hide-details="hided"
                            :dense="dense"
                            :label="'350t ' + $t('Telefon')"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.305.0.t', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.305.0.t', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['305'][0].f[0].val"
                        :rules="validateValue('305', 'f')"
                        style="width:175px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'305f ' + $t('Fax')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row v-for="(sub, ind) in record.fields['305'][0].e" :key="'305e' + ind" dense>
                        <v-col cols="12">
                          <span class="d-none">{{ render }}</span>
                          <v-text-field
                            v-model="sub.val"
                            :rules="validateValue('305', 'e')"
                            :hide-details="hided"
                            :dense="dense"
                            :label="'305e ' + $t('Email')"
                          >
                            <template v-slot:append-outer>
                              <div class="d-inline-flex">
                                <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.305.0.e', ind + 1)">
                                  mdi-plus
                                </v-icon>
                                <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.305.0.e', ind)">
                                  mdi-minus
                                </v-icon>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['305'][0].s[0].val"
                        :rules="validateValue('305', 's')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'305s ' + $t('Site')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['400'][0].a[0].val"
                        :rules="validateValue('400', 'a')"
                        style="width:175px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'400a ' + $t('CUI')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['400'][0].b[0].val"
                        :rules="validateValue('400', 'b')"
                        style="width:175px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'400b ' + $t('Nr. Reg. Comerțului')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['400'][0].d[0].val"
                        :rules="validateValue('400', 'd')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'400d ' + $t('Cont banca IBAN')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['400'][0].c[0].val"
                        :rules="validateValue('400', 'c')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'400c ' + $t('Banca')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['205'][0].a[0].val"
                        :rules="validateValue('205', 'a')"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'205 ' + $t('Nume prenume')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['205'][0].h[0].val"
                        :rules="validateValue('205', 'h')"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'205h ' + $t('Funcția')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['205'][0].j[0].val"
                        :rules="validateValue('205', 'j')"
                        style="width:200px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'205j ' + $t('CNP')"
                      />
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-text-field
                        v-model="record.fields['205'][0].b[0].val"
                        :rules="validateValue('205', 'b')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'205b ' + $t('Serie buletin')"
                      />
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-text-field
                        v-model="record.fields['205'][0].i[0].val"
                        :rules="validateValue('205', 'i')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'205i ' + $t('Număr')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['205'][0].d[0].val"
                        :rules="validateValue('205', 'd')"
                        style="width:175px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'205d ' + $t('Telefon mobil')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['205'][0].f[0].val"
                        :rules="validateValue('205', 'f')"
                        style="width:175px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'205f ' + $t('Telefon fix')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fields['205'][0].g[0].val"
                        :rules="validateValue('205', 'g')"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'205g ' + $t('Email')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-textarea
                        v-model="record.fields['205'][0].c[0].val"
                        :rules="validateValue('205', 'c')"
                        rows="2"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'205c ' + $t('Adresa')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </base-material-tabs>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
// import SDateTime from "../../../components/inputs/DateTime"
// import SAutocomplete from "../../../components/inputs/Autocomplete"

import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit
    // SDateTime,
    // SAutocomplete
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          200: [{ ord: "", a: [{ ord: "", val: "" }] }],
          205: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }]
            }
          ],
          210: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          305: [
            {
              ord: "",
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }]
            }
          ],
          400: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          200: [{ ord: "", a: [{ ord: "", val: "" }] }],
          205: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              i: [{ ord: "", val: "" }],
              j: [{ ord: "", val: "" }]
            }
          ],
          210: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }],
              l: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          305: [
            {
              ord: "",
              e: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              t: [{ ord: "", val: "" }]
            }
          ],
          400: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      schema: {
        dictionary: ["all.JudeteRomania", "all.countriesAlpha3"]
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    validateEmail() {
      const rule = []
      const reg = new RegExp(/^[^@]+@[a-zA-Z0-9._-]+.[a-zA-Z]+$/)
      rule.push(v => !v || reg.test(v) || "Email invalid")
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    getInv() {
      axios.get('ebib_loan_active?aggregate={"$where":{},"$sort":{}}&max_results=10').then(response => {
        this.$log("resinvtm ", response)
      })
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLink(field, subfield, ind, prefix, ctg, e) {
      this.$log("eeee ", e)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
